






















































import { Component, Vue, Prop } from 'vue-property-decorator'
import axios from 'axios'
import { Resource, ResourceType, SlideDeckResourceNumber } from '@/store/modules/resource'
import { SlideDeckPage } from '@/store/modules/slideDeck'
import { GvdPage } from '@/store/modules/gvd'
import { EventBus } from '@/utils/event-bus'
import { saveAs } from 'file-saver'
import { lookup } from 'mime-types'
import ResourcePreviewModal from '@/views/products/resources/ResourcePreview.modal.vue'

@Component({
  components: {
    ResourcePreviewModal
  }
})
export default class SlideDeckResourceList extends Vue {
  @Prop() resources!: Resource[]
  @Prop() resourceTypes!: ResourceType[]
  @Prop() showMarketLabels!: boolean
  @Prop() currentPageKey!: string

  allowPreview: boolean = process.env.ALLOWPREVIEW === 'true'
  selectedResource: Resource | null = null
  downloadMessage: string = this.allowPreview ? 'Preview' : 'Download'
  style1Content: any = this.$store.getters.getBGStyle3
  resourcesForType (resourceTypeKey: string) {
    if (!resourceTypeKey) {
      return this.resources.filter((r: any) => {
        return !r.resourceType
      })
        .sort((a: Resource, b: Resource) => { return a.name < b.name ? -1 : a.name > b.name ? 1 : 0 })
    }
    const _isAdmin = this.$route.path.includes('admin')
    const _publishedEntityKey: string = this.$store.getters.getActiveSlideDeck.publishedEntity ? this.$store.getters.getActiveSlideDeck.publishedEntity.key : ''
    const _resources: Resource[] = JSON.parse(JSON.stringify(this.resources))
      .filter((r: any) => {
        return r.resourceType ? r.resourceType.key === resourceTypeKey : false
      }).map((r: Resource) => {
        if (r.slideDeckResourceNumbers && r.slideDeckResourceNumbers.length) {
          const _slideDeckResourceNoForSlideDeck = r.slideDeckResourceNumbers.find((rn: SlideDeckResourceNumber) => {
            return rn.slideDeck.key === this.$store.getters.getActiveSlideDeck.key ||
            (this.$store.getters.getActiveSlideDeck.publishedEntity && rn.slideDeck.key === this.$store.getters.getActiveSlideDeck.publishedEntity.key)
          })
          if (_slideDeckResourceNoForSlideDeck) {
            r.name = `${_slideDeckResourceNoForSlideDeck.number}. ${r.name}`
          }
        }
        return r
      }).sort((a: Resource, b: Resource) => {
        let _aResNum = -1
        let _bResNum = -1
        if (a.slideDeckResourceNumbers && a.slideDeckResourceNumbers.length) {
          if (_isAdmin && _publishedEntityKey.length) {
            _aResNum = a.slideDeckResourceNumbers.find((rn: SlideDeckResourceNumber) => rn.slideDeck.key === _publishedEntityKey)?.number as number
            _bResNum = b.slideDeckResourceNumbers.find((rn: SlideDeckResourceNumber) => rn.slideDeck.key === _publishedEntityKey)?.number as number
          } else if (!_isAdmin) {
            _aResNum = a.slideDeckResourceNumbers.find((rn: SlideDeckResourceNumber) => rn.slideDeck.key === this.$store.getters.getActiveSlideDeck.key)?.number as number
            _bResNum = b.slideDeckResourceNumbers.find((rn: SlideDeckResourceNumber) => rn.slideDeck.key === this.$store.getters.getActiveSlideDeck.key)?.number as number
          }
        }
        if (_aResNum > _bResNum && (_bResNum > 0)) {
          return 1
        } else if (_aResNum < _bResNum && (_aResNum > 0)) {
          return -1
        }

        // else sort by name
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0
      })
    return _resources
  }

  async downloadFile (resource: Resource) {
    if (process.env.ALLOWPREVIEW && (resource.fileExt === 'png' || resource.fileExt === 'pdf' || resource.fileExt === 'jpg')) {
      this.selectedResource = resource
      this.$bvModal.show('resource-preview-modal')
    } else {
      EventBus.$emit('show-loader')
      const resp = await axios.get(`resources/download/${resource.key}`, { withCredentials: true, responseType: 'blob' })
      if (!resp || resp.status !== 200) {
        EventBus.$emit('hide-loader')
        return
      }
      const _blob = new Blob([resp.data], { type: lookup(resource.filename as string) || undefined })
      saveAs(_blob, resource.filename)
      EventBus.$emit('hide-loader')
    }
  }

  shouldDisableInternalLink (page: SlideDeckPage) {
    if (!page) {
      return false
    }
    if (this.$route.path.includes('admin')) {
      const _draftPage = this.$store.getters.getActiveSlideDeckPages.find((p: SlideDeckPage) => {
        return p.publishedKey === page.key
      })
      if (_draftPage) {
        return this.currentPageKey === _draftPage.key
      } else {
        return false
      }
    } else {
      return this.currentPageKey === page.key
    }
  }

  goToSlideDeckPage (page: SlideDeckPage) {
    if (this.$route.path.includes('admin')) {
      const _page = this.$store.getters.getActiveSlideDeckPages.find((p: SlideDeckPage) => {
        return p.publishedKey === page.key
      })
      if (_page) {
        this.$router.push({ query: { page: _page.key } })
      } else {
        console.warn(`Couldn't find page to jump to: ${_page.key}`)
      }
    } else {
      this.$router.push({ query: { page: page.key } })
    }
  }

  getSlideDeckPageName (page: SlideDeckPage) {
    let _pageNum = 1
    if (this.$route.path.includes('admin')) {
      _pageNum = (this.$store.getters.getActiveSlideDeckPages.findIndex((p: SlideDeckPage) => {
        return p.publishedKey === page.key
      }) + 1)
    } else {
      _pageNum = (this.$store.getters.getActiveSlideDeckPages.findIndex((p: SlideDeckPage) => {
        return p.key === page.key
      }) + 1)
    }

    // const _linkText = `page ${page.orderIdx + 1} of section ${page.gvdSection.orderIdx + 1}`
    const _linkText = `Page ${_pageNum}${page.title.length ? ': "' + page.title + '"' : ''}`
    return _linkText
  }

  goToGvdPage (page: GvdPage) {
    this.$router.push({ name: 'gvd', params: { productKey: this.$store.getters.getActiveProduct.key, gvdKey: page.gvdSection.gvd.key }, query: { page: page.key } })
  }

  getGvdPageName (page: GvdPage) {
    return `'${page.gvdSection.gvd.title}' dossier`
  }
}
