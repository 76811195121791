



































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { SlideDeckSection, SlideDeckPage } from '@/store/modules/slideDeck'
import { EventBus } from '@/utils/event-bus'
import axios from 'axios'
import { saveAs } from 'file-saver'

@Component
export default class CustomSlideDeckOptionsModal extends Vue {
  showAll: boolean = false
  @Prop() slideDeckSections!: SlideDeckSection[]

  get requiredSlideKeys () {
    const _fullDeckSlides = this.$store.getters.getActiveSlideDeckPages
    return _fullDeckSlides ? _fullDeckSlides.filter((slide: SlideDeckPage) => slide.required).map((slide: SlideDeckPage) => slide.key) : []
  }

  get customDeck () {
    return this.$store.getters.getCustomSlideDeck
  }

  get customDeckSlides () {
    return this.customDeck.slides
  }

  isRequired (key: string) {
    return this.requiredSlideKeys.includes(key)
  }

  slidesForSection (section: SlideDeckSection) {
    if (this.showAll) {
      return section.slideDeckPages
    }
    return section.slideDeckPages.filter((slide: SlideDeckPage) => {
      return this.customDeckSlides.find((customSlide: any) => customSlide.key === slide.key) !== undefined
    })
  }

  isPageAdded (slideKey: string) {
    return this.customDeckSlides.find((customSlide: any) => customSlide.key === slideKey) !== undefined
  }

  // isSectionAdded (sectionKey: string) {
  //   return this.customDeckSlides.find((section: SlideDeckSection) => section.key === sectionKey).slideDeckPages.length ===
  // }

  addSlide (slideKey: string) {
    if (this.customDeckSlides.find((customSlide: any) => customSlide.key === slideKey) !== undefined) {
      return
    }
    this.$store.dispatch('addSlideToCustomDeck', slideKey)
  }

  removeSlide (slideKey: string) {
    if (this.customDeckSlides.find((customSlide: any) => customSlide.key === slideKey) === undefined) {
      return
    }
    this.$store.dispatch('removeSlideFromCustomDeck', slideKey)
  }

  // addSection (sectionKey: string) {
  //   this.$store.dispatch('addSectionToCustomDeck', sectionKey)
  // }

  // removeSection (sectionKey: string) {
  //   this.$store.dispatch('removeSectionFromCustomDeck', sectionKey)
  // }

  removeAll () {
    this.$store.dispatch('removeAllCustomDeckSlides')
    this.$bvModal.hide('custom-slide-deck-options-modal')
  }

  async downloadDeck () {
    EventBus.$emit('show-loader')
    const resp = await axios.post(`slide-decks/download-custom/${this.$store.getters.getActiveSlideDeck.key}`, this.customDeck, { withCredentials: true, responseType: 'blob' })
    if (!resp || resp.status !== 200) {
      EventBus.$emit('hide-loader')
      return
    }
    const _blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' })
    saveAs(_blob, 'Slide download.pptx')
    // const url = window.URL.createObjectURL(new Blob([resp.data]))
    // const link = document.createElement('a')
    // link.href = url
    // link.setAttribute('download', `Slide download.pptx`) // TODO: get slide name/page no
    // link.click()
    // link.remove()
    EventBus.$emit('hide-loader')
  }
}
