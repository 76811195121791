











































import { Component, Vue, Prop } from 'vue-property-decorator'
import { SlideDeckPage } from '@/store/modules/slideDeck'
import axios from 'axios'
import { EventBus } from '@/utils/event-bus'
import { saveAs } from 'file-saver'

@Component
export default class SlideOptions extends Vue {
  @Prop() currentPageKey!: string
  @Prop() currentPageNumber!: number

  get requiredSlideKeys () {
    const _fullDeckSlides = this.$store.getters.getActiveSlideDeckPages
    return _fullDeckSlides ? _fullDeckSlides.filter((slide: SlideDeckPage) => slide.required).map((slide: SlideDeckPage) => slide.key) : []
  }

  get isRequired () {
    return this.requiredSlideKeys.includes(this.currentPageKey)
  }

  async downloadSlide () {
    EventBus.$emit('show-loader')
    const resp = await axios.post(`slide-deck-pages/download/${this.$store.getters.getActiveSlideDeck.key}`, { pageNo: this.currentPageNumber }, { withCredentials: true, responseType: 'blob' })
    if (!resp || resp.status !== 200) {
      EventBus.$emit('hide-loader')
      return
    }
    const _blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' })
    saveAs(_blob, 'Slide download.pptx')
    // const url = window.URL.createObjectURL(new Blob([resp.data]))
    // const link = document.createElement('a')
    // link.href = url
    // link.setAttribute('download', `Slide download.pptx`) // TODO: get slide name/page no
    // link.click()
    // link.remove()
    EventBus.$emit('hide-loader')
  }

  get customDeckSlides () {
    return this.$store.getters.getCustomSlideDeck.slides
  }

  get isAdded () {
    return this.customDeckSlides.find((customSlide: any) => customSlide.key === this.currentPageKey) !== undefined
  }

  addSlide () {
    this.$store.dispatch('addSlideToCustomDeck', this.currentPageKey)
  }

  removeSlide () {
    this.$store.dispatch('removeSlideFromCustomDeck', this.currentPageKey)
  }
}
