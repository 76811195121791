













import { Component, Vue, Prop } from 'vue-property-decorator'
import { hasPermissions } from '@/utils/auth'
import axios from 'axios'
import { SlideDeck } from '@/store/modules/slideDeck'
import { EventBus } from '@/utils/event-bus'
import { saveAs } from 'file-saver'

@Component
export default class SlideDeckDownloader extends Vue {
  @Prop() slideDeck?: SlideDeck

  get slideDeckKey () {
    return this.slideDeck ? this.slideDeck.key : ''
  }

  get slideDeckTitle () {
    return this.slideDeck ? this.slideDeck.title : ''
  }

  async downloadFullDeck () {
    EventBus.$emit('show-loader')
    const resp = await axios.get(`slide-decks/download/${this.slideDeckKey}`, { withCredentials: true, responseType: 'blob' })
    if (!resp || resp.status !== 200) {
      EventBus.$emit('hide-loader')
      return
    }
    const _blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' })
    saveAs(_blob, `${this.slideDeckTitle}.pptx`)
    // const url = window.URL.createObjectURL(new Blob([resp.data]))
    // const link = document.createElement('a')
    // link.href = url
    // link.setAttribute('download', `${this.slideDeckTitle}.pptx`)
    // link.click()
    // link.remove()
    EventBus.$emit('hide-loader')
  }

  get canDownload () {
    return hasPermissions(['slideDeck.download'])
  }

  hasPermissions = hasPermissions
}
