

















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class SlideDeckSectionSelect extends Vue {
  @Prop()
  options!: any[]

  @Prop()
  width!: string

  @Prop({ default: null })
  value!: string

  getSectionActiveBackground (sectionActive: boolean) {
    if (sectionActive) {
      return this.$store.getters.getBGStyle3
    }
  }

  get currentSlideDeckSectionKey () {
    return this.value
  }

  set currentSlideDeckSectionKey (newKey: string) {
    this.$emit('input', newKey)
  }
}
