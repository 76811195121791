















import { Component, Vue } from 'vue-property-decorator'

@Component
export default class CustomSlideDeckButton extends Vue {
  get customSlideDeckSlideCount () {
    return this.$store.getters.getCustomSlideDeck.slides.length
  }

  async downloadCustomDeck () {
    this.$bvModal.show('custom-slide-deck-options-modal')
  }
}
